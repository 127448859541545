const host = window.location.hostname

const environment: Record<string, string> = {
  'localhost': 'local',
  'pltnm-app.volum8.dev': 'development',
  'pltnm-app.surge.sh': 'development',
  'pltnm-stage.volum8.dev': 'staging',
  'app.physiqonomics.com': 'production'
}

const env = environment[host] ?? 'local'

export default env
