import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import env from '@/env'
import router from '@/router'

/**
 * Wrapper function to initialize Sentry.
 *
 * @param app The Vue application instance.
 */
const setupSentry = ( app: App<Element> ) => {
  if ( env === 'production' ) {
    Sentry.init( {
      app,
      logErrors: true,
      dsn: 'https://18443d88c7674a4736ac2616c393b532@o4507657085386752.ingest.us.sentry.io/4507657089318912',
      tracePropagationTargets: [ 'localhost', 'app.soundstage.studio', /^\// ],
      integrations: [
        Sentry.browserTracingIntegration( { router } ),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 0.2,
    } )
  }
}

/**
 * Add metadata to the Sentry request.
 */
const configureSentryScope = ( userId: string, userEmail: string, appVersion: string ) => {
  const scope = Sentry.getCurrentScope()
  scope.setUser( { id: userId, email: userEmail } )
  scope.setContext( 'app', { version: appVersion } )
}

export default setupSentry
export { configureSentryScope }
